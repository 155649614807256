import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { Profile } from '../models';
import { map } from 'rxjs/operators';
import {UserService} from './user.service';
import {HttpParams} from '@angular/common/http';
import {ServerResponse} from '../models/server-response.model';

@Injectable()
export class ProfilesService {
  constructor (
      private apiService: ApiService,
      private userService: UserService
  ) {}

  get(username: string): Observable<Profile> {
    const params = new HttpParams({ fromObject: {fn: 'profile',
        username: username,
        userID: this.userService.getCurrentUser().userID,
        nocache: true} });
    return this.apiService.get('', params)
        .pipe(map((data: {data: Profile}) => data.data));
  }
  followers(followUserID: number): Observable<[Profile]> {
    const params = new HttpParams({ fromObject:
          {fn: 'followersList', followUserID: followUserID, userID: this.userService.getCurrentUser().userID} });
    return this.apiService.get('', params)
        .pipe(map((data: {data: [Profile]}) => data.data));
  }
  following(followUserID: number): Observable<[Profile]> {
    const params = new HttpParams({ fromObject:
          {fn: 'followingList', followUserID: followUserID, userID: this.userService.getCurrentUser().userID} });
    return this.apiService.get('', params)
        .pipe(map((data: {data: [Profile]}) => data.data));
  }
  follow(username: string): Observable<ServerResponse> {
    const body = {followerUsername: username};
    return this.apiService.post('?fn=userFollow', body);
  }

  unfollow(username: string): Observable<ServerResponse> {
    const params = new HttpParams({fromObject: {followerUsername: username}});
    return this.apiService.delete('?fn=userUnfollow', params);
  }
  delete(userID: number): Observable<ServerResponse> {
    const params = new HttpParams({fromObject: {deleteUserID: userID}});
    return this.apiService.delete('?fn=profileDelete', params);
  }
}
