<p-toast></p-toast>
<div class="header-search-categories flex flex-column explore-container">
    <div class="mb-2">
        <span class="p-input-icon-right w-full">
                <i class="pi pi-search" (click)="performSearch(searchQuery)"></i>
            <input type="text"
                   class="w-full"
                   pInputText placeholder="{{'header_search' | translate}}"
                   [(ngModel)]="searchQuery"
                   (keyup.enter)="performSearch(searchQuery)"/>
        </span>
    </div>
    <div  class="flex flex-row">
        <div class="hidden categories-stripe md:block">
            <p-splitButton [label]="category.label"
                           [model]="category.items"
                           styleClass="p-button-text p-button-secondary mr-2 mb-2"
                           (onClick)="navigateTo(category.routerLink)"
                           *ngFor="let category of categorieItems">
            </p-splitButton>
        </div>
        <div class="flex-grow-1 md:hidden">
            <p-treeSelect class="w-full"
                          [options]="categorieItems"
                          placeholder="{{'categories' | translate}}"
                          [(ngModel)]="selectedCategoryNode"
                          (onNodeSelect)="categorySelected($event)">
            </p-treeSelect>
        </div>
        <div class="md:flex-grow-1"></div>
        <a class="mx-2 align-self-center" (click)="showFilterModal()"><i class="pi pi-filter mr-4 p-text-secondary"
                                                                         pBadge
                                                                         [value]="selectedFilter.toString()" style="font-size: 2rem"></i></a>
    </div>
</div>
<p-dialog header="{{'select_filters' | translate}}"
          [(visible)]="displayFilterModal"
          [modal]="true"
          [style]="{width: '50vw', height: '350px'}"
          [draggable]="false"
          [resizable]="false"
          [contentStyle]="{'overflow':'visible'}">
    <ng-template pTemplate="content">
        <div class="grid">
            <p-multiSelect class="col-12 md:col-6"
                           [options]="filterCategoriesValue"
                           [(ngModel)]="selectedFilterCategories"
                           styleClass="w-full"
                           defaultLabel="{{'categories' | translate}}"
                           optionLabel="name"
                           optionValue="value"></p-multiSelect>
            <p-dropdown class="col-12 md:col-4"
                        [options]="filterPercentOffValues"
                        [(ngModel)]="selectedPercentageOff"
                        placeholder="{{'discount' | translate}}"
                        optionLabel="name"
                        optionValue="value"
                        [showClear]="true"></p-dropdown>
        </div>

        <h5>{{'price_range' | translate}} {{'from' | translate}} {{rangeValues[0]}}{{'currency_unit' | translate}} {{'to' | translate}} {{rangeValues[1] == 500 ? ('over' | translate) :''}} {{rangeValues[1]}}{{'currency_unit'  | translate}}</h5>
        <p-slider [(ngModel)]="rangeValues" [max]="500" [range]="true"></p-slider>
        <div class="flex ">
            <h5>{{'available_only' | translate}}</h5>
            <p-inputSwitch class="align-self-center ml-3" [(ngModel)]="availableOnly"></p-inputSwitch>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="removeFilters()" label="{{'clear_filter_btn' | translate}}" styleClass="p-button-dlnd-dark"></p-button>
        <p-button icon="pi pi-save" (click)="saveFilter()" label="{{'save_filter_btn' | translate}}" styleClass="p-button-dlnd-dark" *ngIf="userLogged" [loading]="savingFilter"></p-button>
        <p-button icon="pi pi-check" (click)="applyFilters()" label="{{'apply_filter_btn' | translate}}" styleClass="p-button-dlnd"></p-button>
    </ng-template>
</p-dialog>
