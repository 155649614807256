import {Component, Inject, Injector, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';

import { UserService } from './core';
import {NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent} from 'ngx-cookieconsent';
import {filter, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../environments/environment';
import {NavigationEnd, Router} from '@angular/router';
import {NavigationService} from './core/services/navigation.service';
import {CookieService} from 'ngx-cookie-service';
import {isPlatformBrowser} from '@angular/common';

declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    providers: [CookieService]
})
export class AppComponent implements OnInit, OnDestroy {
    private readonly isBrowser: boolean;
    constructor(private userService: UserService,
                private translateService: TranslateService,
                private router: Router,
                private navigationService: NavigationService,
                private cookieService: CookieService,
                private injector: Injector,
                @Inject(PLATFORM_ID) platformId: Object) {
        this.isBrowser = isPlatformBrowser(platformId);
        this.translateService.setDefaultLang(environment.language);
        this.translateService.use(environment.language);
    }
    // keep refs to subscriptions to be able to unsubscribe later
    private popupOpenSubscription: Subscription;
    private popupCloseSubscription: Subscription;
    private initializeSubscription: Subscription;
    private statusChangeSubscription: Subscription;
    private revokeChoiceSubscription: Subscription;
    private noCookieLawSubscription: Subscription;
    ngOnInit() {
        if (this.isBrowser) {
            this.setUpAnalytics();
            if (!this.cookieService.check('cookies_accept')) {
                const ccService = this.injector.get(NgcCookieConsentService);
                this.translateService
                    .get(['cookie.header',
                        'cookie.message',
                        'cookie.dismiss',
                        'cookie.allow',
                        'cookie.deny',
                        'cookie.link',
                        'cookie.policy'])
                    .subscribe(data => {
                        ccService.getConfig().content = ccService.getConfig().content || {} ;
                        ccService.getConfig().content.header = data['cookie.header'];
                        ccService.getConfig().content.message = data['cookie.message'];
                        ccService.getConfig().content.dismiss = data['cookie.dismiss'];
                        ccService.getConfig().content.allow = data['cookie.allow'];
                        ccService.getConfig().content.deny = data['cookie.deny'];
                        ccService.getConfig().content.link = data['cookie.link'];
                        ccService.getConfig().content.policy = data['cookie.policy'];
                        ccService.destroy();
                        ccService.init(ccService.getConfig());
                        this.popupCloseSubscription = ccService.popupClose$.subscribe(
                            () => {
                                this.cookieService.set('cookies_accept', 'true', 365, '/');
                            });
                    });
            }
            // this.ccService.open();
            // this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
            //     () => {
            //         // you can use this.ccService.getConfig() to do stuff...
            //     });
            //
        }
        this.navigationService.startSaveHistory();
        this.userService.populate();
        //
        // this.initializeSubscription = this.ccService.initialize$.subscribe(
        //     (event: NgcInitializeEvent) => {
        //         // you can use this.ccService.getConfig() to do stuff...
        //     });
        //
        // this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
        //     (event: NgcStatusChangeEvent) => {
        //         // you can use this.ccService.getConfig() to do stuff...
        //     });
        //
        // this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
        //     () => {
        //         // you can use this.ccService.getConfig() to do stuff...
        //     });
        //
        // this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
        //     (event: NgcNoCookieLawEvent) => {
        //         // you can use this.ccService.getConfig() to do stuff...
        //     });
        // this.router.events.subscribe((event) => {
        //     if (event instanceof NavigationEnd) {
        //         this.history.push(event.urlAfterRedirects);
        //         window.location.href = event.urlAfterRedirects;
        //         // location.replace();
        //     }
        // });
    }

    ngOnDestroy() {
        // unsubscribe to cookieconsent observables to prevent memory leaks
        // this.popupOpenSubscription.unsubscribe();
        // this.popupCloseSubscription.unsubscribe();
        // this.initializeSubscription.unsubscribe();
        // this.statusChangeSubscription.unsubscribe();
        // this.revokeChoiceSubscription.unsubscribe();
        // this.noCookieLawSubscription.unsubscribe();
    }
    onActivate(event) {
        if (this.isBrowser) {
            window.scroll({
                top: 0,
                left: 0
            });
        }
    }
    setUpAnalytics() {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                gtag('config', 'UA-85179948-1',
                    {
                        page_path: event.urlAfterRedirects
                    }
                );
            });
    }
}
