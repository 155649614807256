import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import { ApiService } from './api.service';
import {distinctUntilChanged} from 'rxjs/operators';
import {Category} from '../models/category.model';

@Injectable()
export class CategoriesService {
    constructor (
        private apiService: ApiService
    ) {
        this.getAll();
    }
    private currentCategoriesSubject = new BehaviorSubject<Array<Category>>([]);
    public currentCategories = this.currentCategoriesSubject.asObservable().pipe(distinctUntilChanged());

    private getAll(): void {
        this.apiService.get('?fn=categoryList').subscribe( data =>
            this.setCategories(data.data)
        );
    }
    setCategories(categories: [Category]) {
        // Set current categories data into observable
        this.currentCategoriesSubject.next(categories);
        return categories;
    }

}
