<div class="grid">
  <app-deal-preview
                     [ngClass]="{'col-12 sm:col-6 xl:col-4':profileList, 'col-12 md:col-6 lg:col-4 xl:col-3 mb-3 lg:mb-0':!profileList}"
                     *ngFor="let deal of results"
                     [deal]="deal"
                     [profileList]="profileList">
  </app-deal-preview>
</div>
<div style="min-height: 350px"
     [hidden]="loading || results?.length > 0">
  {{'no_deal_found' | translate}}
</div>
<div class="text-center my-8" [hidden]="!loading">
  <p-progressSpinner></p-progressSpinner>
</div>
<p-paginator #paginator
             [rows]="limit"
             [totalRecords]="totalDeals"
             (onPageChange)="paginate($event)"></p-paginator>
