import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

import {ApiService} from './api.service';
import {CouponUnits, Deal, DealListConfig} from '../models';
import {map} from 'rxjs/operators';
import {UserService} from './user.service';
import {PrefilledDealModel} from '../models/prefilled-deal.model';
import {ServerResponse} from '../models/server-response.model';
import MiscUtil from '../utils/misc.util';

@Injectable()
export class DealsService {
  constructor (
      private apiService: ApiService,
      private userService: UserService
  ) {}

  query(config: DealListConfig): Observable<{data: Deal[], totalItems: number}> {
    // Convert any filters over to Angular's URLSearchParams
    const params = {};
    if (!isNaN(this.userService.getCurrentUser().userID) && this.userService.getCurrentUser().userID !== undefined) {
      params['userID'] = this.userService.getCurrentUser().userID;
    }
    config.filters = MiscUtil.cleanArrayFromNilAndNan(config.filters);
    Object.keys(config.filters)
        .forEach((key) => {
          params[key] = config.filters[key];
        });
    return this.apiService
        .get(
            ((config.fn === 'deals') ? '?fn=deals' : '?fn=deals'),
            new HttpParams({ fromObject: params })
        );
  }

  get(deal_id): Observable<any> {
    const userQuery = (!isNaN(this.userService.getCurrentUser().userID) && this.userService.getCurrentUser().userID !== undefined) ?
        '&userID=' + this.userService.getCurrentUser().userID : '';
    return this.apiService.get('?fn=deal&nocache=1&linkID=' + deal_id + userQuery)
        .pipe(map(data => data));
  }

  destroy(deal_id): Observable<ServerResponse> {
    const params = new HttpParams({fromObject : {linkID: deal_id}});
    return this.apiService.delete('?fn=dealDelete', params);
  }

  save(deal: PrefilledDealModel, couponUnit: CouponUnits): Observable<ServerResponse> {
    const userID = (!isNaN(this.userService.getCurrentUser().userID) && this.userService.getCurrentUser().userID !== undefined) ?
        this.userService.getCurrentUser().userID : '';
    deal.userID = Number(userID);
    deal.type = 1;
    if (deal.coupon !== null && deal.coupon !== undefined) {
      deal.coupon = (couponUnit === CouponUnits.percentage) ? deal.coupon * -1 : deal.coupon;
    }
    return this.apiService.post('?fn=submitDeal', deal)
        .pipe(map(data => data));

  }
  update(deal: PrefilledDealModel, couponUnit: CouponUnits): Observable<ServerResponse> {
    if (deal.coupon !== null && deal.coupon !== undefined) {
      deal.coupon = (couponUnit === CouponUnits.percentage) ? deal.coupon * -1 : deal.coupon;
    }
    return this.apiService.put('?fn=dealEdit', deal)
          .pipe(map(data => data));
  }
  setAvailability(linkID: number, expired: boolean): Observable<ServerResponse> {
    const path = (expired) ? '?fn=expired' : '?fn=available';
    return this.apiService.put(path, {linkID: linkID})
          .pipe(map(data => data));
  }
  updateImage(linkID: number, imageURL: string) {
    const userID = (!isNaN(this.userService.getCurrentUser().userID) && this.userService.getCurrentUser().userID !== undefined) ?
        this.userService.getCurrentUser().userID : '';
    const body = {'userID': Number(userID), 'linkID': linkID, 'imageURL': imageURL};
    // If we're updating an existing deal
    return this.apiService.put('?fn=dealImageUpdate', body)
        .pipe(map(data => data));
  }
  voteRemove(linkID = null, commentID = null): Observable<ServerResponse> {
    const userID = (!isNaN(this.userService.getCurrentUser().userID) && this.userService.getCurrentUser().userID !== undefined) ?
        this.userService.getCurrentUser().userID : '';
    const type = (linkID == null) ? 'comments' : 'links';
    const params = new HttpParams({fromObject: {'userID': Number(userID), 'linkID': linkID, 'commentID': commentID, 'voteType': type}});
    return this.apiService.delete('?fn=voteDelete', params);
  }
  upVote(linkID = null, commentID = null): Observable<ServerResponse> {
    const userID = (!isNaN(this.userService.getCurrentUser().userID) && this.userService.getCurrentUser().userID !== undefined) ?
        this.userService.getCurrentUser().userID : '';
    const type = (linkID == null) ? 'comments' : 'links';
    const body = {'userID': Number(userID), 'linkID': linkID, 'commentID': commentID, 'voteType': type};
    return this.apiService.post('?fn=upvote', body);
  }
  downVote(linkID = null, commentID = null): Observable<ServerResponse> {
    const userID = (!isNaN(this.userService.getCurrentUser().userID) && this.userService.getCurrentUser().userID !== undefined) ?
        this.userService.getCurrentUser().userID : '';
    const type = (linkID == null) ? 'comments' : 'links';
    const body = {'userID': Number(userID), 'linkID': linkID, 'commentID': commentID, 'voteType': type};
    return this.apiService.post('?fn=downvote', body);
  }
}
