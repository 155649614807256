// import {window} from 'global';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {CommentsService, Deal, DealsService, UserService} from '../../core';
import {environment} from '../../../environments/environment';
import {concatMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {ServerResponse} from '../../core/models/server-response.model';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {AuthComponent} from '../../auth/auth.component';
import {MessageService} from 'primeng/api';
import MiscUtil from '../../core/utils/misc.util';
import AssetsUtil from '../../core/utils/assets.util';
import {TranslateService} from '@ngx-translate/core';
declare var gtag: Function;
@Component({
    selector: 'app-deal-preview',
    templateUrl: './deal-preview.component.html',
    providers: [DialogService, MessageService]
})
export class DealPreviewComponent implements OnInit, OnDestroy {
    constructor(
        private userService: UserService,
        private dealService: DealsService,
        private dialogService: DialogService,
        private messageService: MessageService,
        private translate: TranslateService) {
    }
    @Input() deal: Deal;
    @Input() imageDeal = '';
    @Input() profileList = false;
    isExpired = false;
    ref: DynamicDialogRef;
    dealImageFallback = AssetsUtil.loadDealFallbackOnError();
    goToUrl() {
        gtag('event', 'click', {
            'event_category': 'go_to_deal',
            'event_label': this.deal.link_url
        });
        gtag('event', 'conversion', {
            'send_to': environment.gtag_conversion,
            'link_id': this.deal.link_id
        });
        window.open(this.deal.link_url);
    }
    ngOnInit(): void {
        if (this.deal.file_name.includes('http')) {
            this.imageDeal = this.deal.file_name;
        } else {
            this.imageDeal = environment.assets_url + '/modules/upload/attachments/' + this.deal.file_name;
        }
        this.isExpired = (this.deal.link_expired > 0);
    }
    needLogin() {
        this.ref = this.dialogService.open(AuthComponent, {
            styleClass: 'login-popup',
            dismissableMask: true,
            data: 'signup'
        });

        this.ref.onClose.subscribe((product) =>
            this.ref.destroy()
        );
    }
    voteRemovePress(value: number) {
        this.dealService.voteRemove(this.deal.link_id).pipe(tap(
            data => this.handleServerResponse(data, value, true)
        )).subscribe();
    }
    votePress(value: number) {
        if (this.deal.vote_value === 10 || this.deal.vote_value === -10) {
            // already voted
            this.messageService.add({severity: 'warn', summary: this.translate.instant('deal_vote_already')});
            return;
        }
        if (value > 0 ) {
            // UpVote
            this.userService.isAuthenticated.pipe(concatMap(
                (authenticated) => {
                    // Not authenticated? Push to login screen
                    if (!authenticated) {
                        this.needLogin();
                        return of(null);
                    }
                    return this.dealService.upVote(this.deal.link_id).pipe(tap(
                        data => this.handleServerResponse(data, value)
                    ));
                }
            )).subscribe();
        } else {
            // DownVote
            this.userService.isAuthenticated.pipe(concatMap(
                (authenticated) => {
                    // Not authenticated? Push to login screen
                    if (!authenticated) {
                        this.needLogin();
                        return of(null);
                    }
                    return this.dealService.downVote(this.deal.link_id).pipe(tap(
                        data => this.handleServerResponse(data, value)
                    ));
                }
            )).subscribe();
        }

    }
    handleServerResponse(resp: ServerResponse, value, isRemove = false) {
        if (resp['ok']) {
            if (isRemove) {
                this.deal.link_votes -= value / 10;
                this.deal.vote_value = 0;
            } else {
                this.deal.link_votes += value / 10;
                this.deal.vote_value = value;
            }

        }
    }
    ngOnDestroy() {
        if (this.ref) {
            this.ref.close();
        }
    }
}
