import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({name: 'dateperiod'})
export class DatePeriodPipe implements PipeTransform {
    constructor(private translation: TranslateService) {
    }
    private space = ' ';
    handleDays(days: number): string {
        const weeks = Math.floor(days / 7);
        const months = Math.floor(days / 30);
        const years = Math.floor(days / 365);
        switch (true) {
            case years === 1:
                return this.translation.instant('year_ago');
            case years > 1:
                return years + this.space + this.translation.instant('years_ago');
            case months === 1:
                return this.translation.instant('month_ago');
            case months > 1:
                return months + this.space + this.translation.instant('months_ago');
            // case weeks > 1:
            //     return weeks + this.space + this.translation.instant('weeks_ago');
            default:
                return days + this.space + this.translation.instant('days_ago');
        }
    }
    transform(value: string): string {
        value = value.replace(/ /g, 'T') + 'Z';
        const diff = Date.now() - Date.parse(value);
        const days = Math.floor(diff / (60 * 60 * 24 * 1000));
        const hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
        const minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
        const seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
        switch (true) {
            case days === 1:
                return this.translation.instant('yesterday');
            case days > 1:
                return this.handleDays(days);
            case hours === 1:
                return this.translation.instant('hour_ago');
            case hours > 1:
                return hours + this.space + this.translation.instant('hours_ago');
            case minutes > 1:
                return minutes + this.space + this.translation.instant('minutes_ago');
            case seconds < 61:
                return this.translation.instant('now');
        }
    }
}
