import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, BehaviorSubject, ReplaySubject, delay} from 'rxjs';

import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { User } from '../models';
import { map ,  distinctUntilChanged } from 'rxjs/operators';
import {ServerResponse} from '../models/server-response.model';
import {isPlatformBrowser} from '@angular/common';


@Injectable()
export class UserService {
  private readonly isBrowser: boolean;
  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor (
      private apiService: ApiService,
      private http: HttpClient,
      private jwtService: JwtService,
      @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  populate() {
    // If JWT detected, attempt to get & store user's info
    if (this.isBrowser) {
      if (this.jwtService.getToken()) {
        this.apiService.get('?fn=user&nocache=1')
            .subscribe(
                data => this.setAuth(data),
                err => this.purgeAuth()
            );
      } else {
        // Remove any potential remnants of previous auth states
        this.purgeAuth();
      }
    }
  }

  setAuth(user: User) {
    // Save token sent from server in cookies
    this.jwtService.saveToken(user.token);
    // Set current user data into observable
    this.currentUserSubject.next(user);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    // Remove Token from cookies
    this.jwtService.destroyToken();
    // Set current user to an empty object
    this.currentUserSubject.next({} as User);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
  }

  attemptAuth(type, credentials): Observable<User> {
    const route = `?fn=${type}`;
    return this.apiService.post(route, credentials)
        .pipe(map(
            data => {
              this.setAuth(data);
              return data;
            }
        ));
  }

  getCurrentUser(): User {
    return this.currentUserSubject.value;
  }
  requestReset(email): Observable<ServerResponse> {
    const params = new HttpParams({
      fromObject: {
        email: email
      }});
    return this.apiService
        .get('?fn=passwordReset', params)
        .pipe(map( data => data));
  }
  resetPassword(password): Observable<ServerResponse> {
    return this.apiService
        .put('?fn=passwordResetUpdate', password)
        .pipe(map( data => data));
  }
  updatePassword(passwords): Observable<ServerResponse> {
    return this.apiService
        .put('?fn=passwordUpdate', passwords)
        .pipe(map( data => data));
  }
  // Update the user on the server (email, pass, etc)
  update(user): Observable<ServerResponse> {
    return this.apiService
        .put('?fn=updateUserInformation',  user )
        .pipe(map(data => data));
  }
  updateAvatar(file): Observable<ServerResponse> {
    return this.apiService.upload(file, true)
        .pipe(map(data => data));
  }
}
