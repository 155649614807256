<app-layout-category></app-layout-category>
<div class="home-page">

  <div class="breadcrumb-container page">
      <div class="highlights my-3">
        <p [innerHTML]="breadcrumbTitle"></p>
      </div>
        <app-deal-list [limit]="24"
                       [config]="listConfig"></app-deal-list>
  </div>
</div>
