import { Component, OnInit } from '@angular/core';
import {CategoriesService, UserService} from '../../core';
import {Category} from '../../core/models/category.model';
import {ActivatedRoute, Router} from '@angular/router';
import {MenuItem, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {tap} from 'rxjs/operators';
import {FilterService} from '../../core/services/filter.service';
import {ErrorUtil} from '../../core/utils/error.util';

@Component({
    selector: 'app-layout-category',
    templateUrl: './category.component.html',
    providers: [MessageService]
})
export class CategoryComponent implements OnInit {
    constructor(
        private categoriesService: CategoriesService,
        private router: Router,
        private route: ActivatedRoute,
        private translation: TranslateService,
        private user: UserService,
        private messageService: MessageService,
        private filterService: FilterService
    ) {}

    categories: Array<Category> = [];
    categoriesLoaded = false;

    parents: [Category, Array<Category>][] = [];
    searchQuery: string;
    categorieItems: Array<any> = [];
    filterCategoriesValue: Array<any> = [];
    selectedCategoryNode: any;
    homeItem: MenuItem = {label: ' Popular deal', icon: 'pi pi-home', routerLink: ['/']};
    filterPercentOffValues: Array<any> = [];

    selectedFilter = 0;
    displayFilterModal = false;
    selectedFilterCategories: string[] = [];
    selectedPercentageOff = undefined;
    rangeValues: number[] = [0, 500];
    availableOnly = false;
    userLogged = false;
    filterApplied = false;
    savingFilter = false;

    ngOnInit(): void {
        this.user.isAuthenticated.pipe(tap( logged =>
            this.userLogged = logged
        )).subscribe();
        this.categoriesService.currentCategories
            .subscribe(categories => {
                if ( categories === []) { return; }
                this.categories = categories;
                this.filterCategories();
                this.setupRouteListener();
            });
        this.filterPercentOffValues = [
            {name: this.translation.instant('all'), value: 0},
            {name: '> 10%', value: 10},
            {name: '> 20%', value: 20},
            {name: '> 50%', value: 50},
            {name: '> 75%', value: 75},
            {name: '> 90%', value: 90}
        ];
    }
    setupRouteListener() {
        this.route.queryParams.subscribe(params => {
            this.setupParamsUI(params);
        });
    }
    setupParamsUI(params) {
        this.selectedFilter = 0;
        if (params.hasOwnProperty('categories')) {
            this.selectedFilter += 1;
            this.selectedFilterCategories = params['categories'].split(',');
        }
        if (params.hasOwnProperty('minPrice')) {
            this.rangeValues[0] = Number(params['minPrice']);
        }
        if (params.hasOwnProperty('maxPrice')) {
            this.rangeValues[1] = Number(params['maxPrice']);
        }
        if (params.hasOwnProperty('minSaving')) {
            this.selectedPercentageOff = Number(params['minSaving']);
            this.selectedFilter += 1;
        }
        if (params.hasOwnProperty('onlyAvailable')) {
            this.availableOnly = true;
            this.selectedFilter += 1;
        }
        if (this.rangeValues[0] !== 0 || this.rangeValues[1] !== 500) {
            this.selectedFilter += 1;
        }
    }
    filterCategories() {
        const parents = this.categories.filter( z => z.category_parent === 0 );
        parents.forEach( x =>
            this.parents.push([x, this.categories.filter(s => s.category_parent === x.category_id).sort((a, b) => a.category_order - b.category_order)]));
        this.parents = this.parents.sort((a, b) => a[0].category_order - b[0].category_order);
        // this.categoriesLoaded = true;
        this.parents.forEach( parent =>
            this.categorieItems.push({
                label: parent[0].category_name,
                routerLink: ['/category', parent[0].category_safe_name],
                items: parent[1].map(element => ({
                    label: element.category_name,
                    routerLink: ['/category', element.category_safe_name]
                })),
                children: parent[1].map(element => ({
                    label: element.category_name,
                    routerLink: ['/category', element.category_safe_name]
                }))
            }));
        this.categoriesLoaded = true;
        this.categories.forEach( cat =>
            this.filterCategoriesValue.push({name: cat.category_name, value: cat.category_id.toString()})
        );
    }

    performSearch(searchQuery: string) {
        this.router.navigate(['/search', searchQuery]);
    }

    navigateTo(route: string[]) {
        this.selectedCategoryNode = null;
        this.router.navigate(route);
    }
    categorySelected($event) {
        this.selectedCategoryNode = $event.node;
        this.router.navigate($event.node.routerLink);
    }
    showFilterModal() {
        this.displayFilterModal = true;
    }
    applyFilters() {
        this.displayFilterModal = false;
        let queryCategories = null;
        let queryDiscount = null;
        let queryPriceMin = null;
        let queryPriceMax = null;
        let queryExpiredExclude = null;
        if (this.selectedFilterCategories?.length > 0) {
            queryCategories = this.selectedFilterCategories.toString();
        }
        if (this.selectedPercentageOff !== undefined && this.selectedPercentageOff > 0 ) {
            queryDiscount = this.selectedPercentageOff;
        }
        if (this.rangeValues !== [0, 500]) {
            queryPriceMin = (this.rangeValues[0] > 0) ? this.rangeValues[0] : null;
            if (this.rangeValues[1] < 500) {
                queryPriceMax = this.rangeValues[1];
            }
        }
        if (this.availableOnly !== undefined && this.availableOnly !== false) {
            queryExpiredExclude = (this.availableOnly ? 'true' : null);
        }
        this.router.navigate(['/' + this.translation.instant('filter_url')], {
            queryParams: { categories: queryCategories,
                minPrice: queryPriceMin,
                maxPrice: queryPriceMax,
                minSaving: queryDiscount,
                onlyAvailable: queryExpiredExclude},
            queryParamsHandling: 'merge'
        });
    }

    removeFilters() {
        this.selectedFilter = 0;
        this.selectedFilterCategories = [];
        this.selectedPercentageOff = undefined;
        this.rangeValues = [0, 500];
        this.availableOnly = false;
        this.displayFilterModal = false;
        this.filterApplied = false;
    }

    saveFilter() {
        this.savingFilter = true;
        const minPrice = (this.rangeValues[0] > 0 ) ? this.rangeValues[0] : undefined;
        const maxPrice = (this.rangeValues[1] < 500 ) ? this.rangeValues[1] : undefined;
        this.filterService.add(this.selectedFilterCategories,
            minPrice,
            maxPrice,
            this.selectedPercentageOff,
            undefined,
            this.availableOnly,
            undefined,
            undefined).pipe(tap( date => {
            this.messageService.add({severity: 'success',
                summary: this.translation.instant('filter_notification_header'),
                detail: this.translation.instant('filter_notification_content')});
            setTimeout(() => {
                this.applyFilters();
                this.savingFilter = false;
            }, 2000);
        }, err => {
            this.savingFilter = false;
            this.messageService.add({severity: 'error',
                summary: this.translation.instant('error'),
                detail: this.translation.instant(new ErrorUtil(err.error).description)
            });
        })).subscribe();
    }
}



















