import {environment} from '../../../environments/environment';

export default class AssetsUtil {
    static get_small_avatar_url(userID) {
        return environment.assets_url + '/avatars/user_uploaded/' + userID + '_32.jpg';
    }
    static get_big_avatar_url(userID) {
        return environment.assets_url + '/avatars/user_uploaded/' + userID + '_100.jpg';
    }
    static get_orig_avatar_url(userID) {
        return environment.assets_url + '/avatars/user_uploaded/' + userID + '_original.jpg';
    }
    static loadAvatarFallbackOnError() {
        return '../assets/avatar.svg';
    }
    static loadDealFallbackOnError() {
        return '../assets/no_image.svg';
    }
}
