import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  {
    path: 'password-reset',
    loadChildren: () => import('./password-reset/password-reset.module').then(m => m.PasswordResetModule)
  },
  // {
  //   path: 'contact',
  //   loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)
  // },
  {
    path: 'policy',
    loadChildren: () => import('./policy/policy.module').then(m => m.PolicyModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'profilo',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'submit',
    loadChildren: () => import('./submit/submit.module').then(m => m.SubmitModule)
  },
  {
    path: 'edit',
    loadChildren: () => import('./edit/edit.module').then(m => m.EditModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'page/:page',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'deal',
    loadChildren: () => import('./deal/deal.module').then(m => m.DealModule)
  },
  {
    path: 'offerta',
    loadChildren: () => import('./deal/deal.module').then(m => m.DealModule)
  },
  {
    path: 'category/:catName/:page',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'search/:query/:page',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'category/:catName',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'search/:query',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'store/:store',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'filter/:categories/:saving/:priceMin/:priceMax/:expried',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'filter',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'filtro',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'negozio/:store',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'terms-conditions',
    loadChildren: () => import('./terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule)
  },
  {
    path: 'termini-condizioni',
    loadChildren: () => import('./terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule)
  },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preload all modules; optionally we could
    // implement a custom preloading strategy for just some
    // of the modules (PRs welcome 😉)
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy',
    paramsInheritanceStrategy: 'always'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
