import { Component, OnInit } from '@angular/core';

import { User, UserService } from '../../core';
import {environment} from '../../../environments/environment';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {AuthComponent} from '../../auth/auth.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-layout-header',
  templateUrl: './header.component.html',
  providers: [DialogService]
})
export class HeaderComponent implements OnInit {
  constructor(
      private userService: UserService,
      private dialogService: DialogService,
      private router: Router
  ) {}

  ref: DynamicDialogRef = null;
  currentUser: User;
  isAuthenticated: boolean;

  ngOnInit() {
    this.userService.currentUser.subscribe(
        (userData) => {
          this.currentUser = userData;
        }
    );
    this.userService.isAuthenticated.subscribe(
        (isAuthenticated) => {
          this.isAuthenticated = isAuthenticated;
        }
    );
  }

  get_avatar_url() {
    return environment.assets_url + '/avatars/user_uploaded/' + this.currentUser.userID + '_100.jpg';
  }
  loadFallbackOnError() {
    return '../../../assets/avatar.svg';
  }

  requestLogin() {
    this.ref = this.dialogService.open(AuthComponent, {
      styleClass: 'login-popup',
      dismissableMask: true,
      data: 'login'
    });
    this.ref.onClose.subscribe( data =>
        this.ref.destroy()
    );
  }

  submitNewClick() {
    if (!this.isAuthenticated) {
      this.requestLogin();
    } else {
      this.router.navigateByUrl('/submit');
    }
  }

  gotoSocial(social: string) {
    switch (social) {
      case 'telegram':
        window.open(environment.telegram_url, '_blank');
        break;
      case 'twitter':
        window.open(environment.twitter_url, '_blank');
        break;
      case 'facebook':
        window.open(environment.facebook_url, '_blank');
        break;
    }
  }
}
