import {UserLevels} from './user.model';

export interface Deal {
  link_id: number;
  link_category: number;
  link_title: string;
  link_title_url: string;
  link_url_title: string;
  link_content: string;
  link_comments: number;
  link_date: string;
  link_url: string;
  link_price: number;
  link_list_price: number;
  link_shipping: number;
  link_percent_off: number;
  link_coupon: number;
  link_promo_code: string;
  link_promotion_type: PromotionType;
  link_store: string;
  link_image: string;
  link_votes: number;
  link_expired: number;
  link_author: number;
  user_login: string;
  user_level: UserLevels;
  file_name: string;
  relatedDeals: [Deal];
  vote_value: number;
  socialContent: string;
}
export interface CouponUnit {
  name: string;
  code: CouponUnits;
  class: string;
}
export enum CouponUnits {
  percentage,
  value
}
export enum PromotionType {
   none,
   promocode,
   CartDiscount,
   AmazonFlash,
   AmazonCoupon,
   AmazonCouponAndFlash,
   Cashback
}
