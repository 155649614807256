import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '../../../environments/environment';

@Pipe({name: 'dealimageurl'})
export class DealImageUrlPipe implements PipeTransform {
    transform(value: string): string {
        if (!value.startsWith('http')) {
           return `${environment.assets_url}/modules/upload/attachments/thumbs/${value}`;
        }
        return value.replace(/\u00a0/g, ' ');
    }
}
