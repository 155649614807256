import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { Comment } from '../models';
import { map } from 'rxjs/operators';
import {UserService} from './user.service';


@Injectable()
export class CommentsService {
  constructor (
    private apiService: ApiService,
    private userService: UserService
  ) {}

  add(linkID, payload): Observable<Comment> {
    const userID = (!isNaN(this.userService.getCurrentUser().userID) && this.userService.getCurrentUser().userID !== undefined) ?
        this.userService.getCurrentUser().userID : '';
    const body = {'userID': Number(userID), 'linkID': linkID, 'content': payload};
    return this.apiService
    .post(
      `?fn=commentAdd`,
      body
    ).pipe(map(data => data.data));
  }

  getAll(linkID): Observable<Comment[]> {
    return this.apiService.get(`?fn=comments&linkID=${linkID}&nocache=1`)
      .pipe(map(data => data.data));
  }

  destroy(commentId, linkID) {
    return this.apiService
           .delete(`?fn=commentDel&linkID=${linkID}&commentID=${commentId}`);
  }

  edit(comment) {
    const userID = (!isNaN(this.userService.getCurrentUser().userID) && this.userService.getCurrentUser().userID !== undefined) ?
        this.userService.getCurrentUser().userID : '';
    const body = {'userID': Number(userID), 'commentID': comment.comment_id, 'content': comment.comment_content};
    return this.apiService
        .put(`?fn=commentEdit`, body);
  }
}
