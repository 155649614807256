import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { DealListComponent, DealDetailMetaComponent, DealPreviewMetaComponent, DealPreviewComponent } from './deal-helpers';
import { FollowButtonComponent } from './buttons';
import { ListErrorsComponent } from './list-errors.component';
import { ShowAuthedDirective } from './show-authed.directive';
import { ImgFallbackDirective} from './fallback-img.directive';
import {FlexModule} from '@angular/flex-layout';
import {ToastModule} from 'primeng/toast';
import {PaginatorModule} from 'primeng/paginator';
import {ImageModule} from 'primeng/image';
import {CardModule} from 'primeng/card';
import {DealRelatedDetailsComponent} from './deal-helpers/deal-related.component';
import { ReplaceSpacePipe } from '../core/pipes/replace-space.pipe';
import {DatePeriodPipe} from '../core/pipes/date-period.pipe';
import {DealImageUrlPipe} from '../core/pipes/image-url.pipe';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {TranslateModule} from '@ngx-translate/core';
import {UrlSafePipe} from '../core/pipes/url-safe.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule,
        FlexModule,
        ToastModule,
        PaginatorModule,
        ImageModule,
        CardModule,
        ProgressSpinnerModule,
        TranslateModule
    ],
    declarations: [
        DealListComponent,
        DealPreviewMetaComponent,
        DealDetailMetaComponent,
        DealPreviewComponent,
        DealRelatedDetailsComponent,
        FollowButtonComponent,
        ListErrorsComponent,
        ShowAuthedDirective,
        ImgFallbackDirective,
        ReplaceSpacePipe,
        DatePeriodPipe,
        DealImageUrlPipe,
        UrlSafePipe
    ],
    exports: [
        DealListComponent,
        DealPreviewMetaComponent,
        DealDetailMetaComponent,
        DealPreviewComponent,
        DealRelatedDetailsComponent,
        CommonModule,
        FollowButtonComponent,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ListErrorsComponent,
        RouterModule,
        ShowAuthedDirective,
        ImgFallbackDirective,
        ReplaceSpacePipe,
        DatePeriodPipe,
        DealImageUrlPipe,
        UrlSafePipe,
        TranslateModule
    ]
})
export class SharedModule {}
