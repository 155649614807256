<div class="flex flex-column w-full">
  <div class="flex flex-row text-left align-items-center">
    <p *ngIf="deal.link_price == 0" class="font-semibold text-3xl deal-preview-price my-1">{{'free' | translate}}</p>
    <p *ngIf="deal.link_price != 0" class="font-semibold text-3xl deal-preview-price my-1">{{ deal.link_price | number: '1.0-2'}}{{'currency_unit' | translate}}</p>
    <p *ngIf="oldPriceVisible" class="ml-2 font-medium text-xl strikethrough my-1">{{ deal.link_list_price | number: '1.0-2'}}{{'currency_unit' | translate}}</p>
  </div>
  <p *ngIf="savingVisible" class="font-medium text-xl deal-preview-saving mt-1">{{ deal.link_percent_off }}%</p>
  <div class="flex-grow-1 flex">
    <div class="flex flex-column pl-0 w-full">
      <div class="deal-meta-store-shape">
        <img class="deal-meta-time p-1" src="assets/store.svg"/><a [routerLink]="['/store',deal.link_store]" >{{deal.link_store}}</a>
      </div>
      <div class="deal-meta-shape author" *ngIf="!deletedUser">
        <a href="{{'profile_url'| translate}}/{{ deal.user_login }}"><img class="deal-meta-avatar border-circle p-1" src="{{getAvatarImage()}}" appImgFallback='{{imageFallback}}'/>{{ deal.user_login }}</a>
      </div>
      <div class="deal-meta-shape h-1rem author" *ngIf="deletedUser">
        <a class="ml-1 p-1" href="{{'profile_url'| translate}}/{{ deal.user_login }}">{{ 'deleted_user' | translate }}</a>
      </div>
      <div class="deal-meta-shape mb-0">
        <img class="deal-meta-time p-1" src="assets/clock.svg"/> {{deal.link_date | dateperiod}}
      </div>
    </div>
  </div>
</div>
