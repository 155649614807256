import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { environment } from '../../../environments/environment';

import { Deal, DealListConfig, DealsService } from '../../core';
import {Observable} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import Utils from '../../utils';
import {Paginator} from 'primeng/paginator';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-deal-list',
  templateUrl: './deal-list.component.html'
})
export class DealListComponent {
  selector = '.home-deal-list';
  busyGettingData = false;
  totalDeals = 0;
  constructor (
      private dealsService: DealsService,
      private router: Router,
      private route: ActivatedRoute,
      private translate: TranslateService
  ) {}

  @ViewChild('paginator', { static: true }) paginator: Paginator;

  @Input() results: Deal[];
  @Input() limit: number;
  @Input() profileList = false;
  @Input()
  set config(config: Observable<DealListConfig>) {
    if (config) {
      config.subscribe( list =>
          this.listUpdated(list)
      );
    }
  }

  query: DealListConfig;
  loading = true;
  currentPage = 0;
  currentScrollPos = 0;
  totalPages: Array<number> = [1];
  isDataLoaded = false;

  listUpdated(list: DealListConfig) {
    this.loading = true;
    if (list !== null && list !== undefined) {
      this.query = list;
      this.isDataLoaded = false;
      this.runQuery();
    }
  }
  setPageTo(pageNumber) {
    const params = this.router.url.split('/');
    this.currentPage = Number(pageNumber);
    this.isDataLoaded = false;
    if  (Utils.countProps(params) > 3 && params[1].includes(this.translate.instant('profile_url'))) {
      this.router.navigate(['/' + this.translate.instant('profile_url') + params[2] +  this.translate.instant('deal_url'), pageNumber + 1]);
    } else if (params[1].includes(this.translate.instant('filter_url'))) {
      this.router.navigate(['/' + this.translate.instant('filter_url')], {
        queryParams: { categories: this.route.snapshot.queryParams['categories'],
          minPrice: this.route.snapshot.queryParams['minPrice'],
          maxPrice: this.route.snapshot.queryParams['maxPrice'],
          minSaving: this.route.snapshot.queryParams['minSaving'],
          onlyAvailable: this.route.snapshot.queryParams['onlyAvailable'],
          page: pageNumber + 1},
        queryParamsHandling: 'merge'
      });
    } else if (Utils.countProps(params) > 2 && !params[1].includes('page')) {
      this.router.navigate([params[1], params[2], pageNumber + 1]);
    } else {
      this.router.navigate(['/page', pageNumber + 1]);
    }
  }

  runQuery($deals: Deal[] = []) {
    this.loading = true;
    this.results = $deals;
    const queryPage = this.query.filters.page;
    // eslint-disable-next-line max-len
    this.currentPage = (queryPage === undefined || isNaN(queryPage) || queryPage <= 0 || queryPage === null) ? 0 : Number(this.query.filters.page - 1);
    this.query.filters.page = null;
    // Create limit and offset filter (if necessary)
    if (this.limit) {
      this.query.filters.limit = this.limit;
      this.query.filters.offset =  (this.limit * (this.currentPage));
    }

    this.dealsService.query(this.query)
        .subscribe(data => {
          this.loading = false;
          this.results = data.data;
          this.totalDeals = data.totalItems;
          this.busyGettingData = false;
          this.paginator.changePage(this.currentPage);
        });
  }

  onScrollDown($event: any) {
    // if ($event['currentScrollPosition'] > this.currentScrollPos) {
    //   this.busyGettingData = true;
    //   this.currentPage += 1;
    //   this.currentScrollPos = $event['currentScrollPosition'];
    //   this.runQuery(this.results);
    // }
    // console.log('scrolled down!! now page: ', this.currentPage, $event);
  }

  paginate($event: any) {
    if ($event.page !== (this.currentPage)) {
      this.setPageTo($event.page);
    }
  }
}
