import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { HomeComponent } from './home.component';
import { HomeAuthResolver } from './home-auth-resolver.service';
import { SharedModule } from '../shared';
import { HomeRoutingModule } from './home-routing.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {InputTextModule} from 'primeng/inputtext';
import {SplitButtonModule} from 'primeng/splitbutton';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {TreeSelectModule} from 'primeng/treeselect';
import {DropdownModule} from 'primeng/dropdown';
import {CategoryModule} from '../shared/layout/category.module';
import {NgcCookieConsentModule} from 'ngx-cookieconsent';

@NgModule({
    imports: [
        SharedModule,
        HomeRoutingModule,
        FlexLayoutModule,
        InputTextModule,
        SplitButtonModule,
        BreadcrumbModule,
        TreeSelectModule,
        DropdownModule,
        CategoryModule,
        NgcCookieConsentModule
    ],
  declarations: [
      HomeComponent
  ],
  providers: [
    HomeAuthResolver
  ]
})
export class HomeModule {}
