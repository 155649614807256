import {environment} from '../../../environments/environment';
import * as CryptoJS from 'crypto-js';

export default class AuthUtil {
    private static key = CryptoJS.enc.Utf8.parse(environment.kpw);
    private static iv = CryptoJS.enc.Utf8.parse(environment.ipv);
    static generateEncryptedPwd($pwd) {
        return CryptoJS.AES.encrypt($pwd, this.key, { iv: this.iv, padding: CryptoJS.pad.Pkcs7 }).toString();
    }
}
