import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ServerResponse} from '../models/server-response.model';
import {Notification} from '../models/notification.model';


@Injectable()
export class NotificationService {
    constructor (
        private apiService: ApiService
    ) {}

    add(notificationType, linkID, authorID, filterID): Observable<Notification> {
        const body = {notificationType: notificationType,
            linkID: linkID,
            filterID: filterID,
            authorID: authorID};
        return this.apiService
            .post(
                `?fn=saveNotification`,
                body
            ).pipe(map(data => data.data));
    }
    getAll(): Observable<Notification[]> {
        return this.apiService.get(`?fn=getNotifications&nocache=1`)
            .pipe(map(data => data.data));
    }
    destroy(notificationID): Observable<ServerResponse> {
        return this.apiService
            .delete(`?fn=deleteNotification&notificationID=${notificationID}`)
            .pipe(map(data => data));
    }
    editStatus(enable: boolean, notificationID: number): Observable<Notification>  {
        const body = (enable) ? {enableNotification: true, notificationID: notificationID}
            : {disableNotification: true, notificationID: notificationID};
        return this.apiService
            .put(
                `?fn=updateNotificationStatus`,
                body
            ).pipe(map(data => data.data));
    }
}
