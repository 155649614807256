<div class="header-style flex flex-row justify-content-between align-items-center header-container md:py-2">
    <div class="flex flex-grow-1 flex-shrink-1 header-logo w-full bg-contain bg-no-repeat bg-left cursor-pointer" routerLink="/"></div>

    <div class="flex flex-grow-1 align-items-center justify-content-between" style="max-width: 20rem">
        <button pButton class="p-button-rounded p-button-header-social" icon="icon-telegram" (click)="gotoSocial('telegram')"></button>
        <button pButton class="p-button-rounded p-button-header-social" icon="icon-twitter" (click)="gotoSocial('twitter')"></button>
        <button pButton class="p-button-rounded p-button-header-social" icon="icon-facebook" (click)="gotoSocial('facebook')"></button>
        <button pButton class="p-button-rounded p-button-header-social" icon="icon-plus" (click)="submitNewClick()"></button>

    <div class="h-4rem w-4rem">
        <a href="/profile/{{currentUser.username}}"
           routerLinkActive="active"
           *appShowAuthed="true">
            <img class="border-circle h-4rem" src="{{get_avatar_url()}}" appImgFallback='{{loadFallbackOnError()}}'/>
        </a>
        <a (click)="requestLogin()" *appShowAuthed="false">
            <img class="border-circle h-4rem" src="{{loadFallbackOnError()}}"/>
        </a>
    </div>
    </div>

</div>
