<div class="flex flex-column col-6 pl-0" [ngClass]="{'h-7rem': profileList}">
  <div class="deal-meta-shape author" *ngIf="!profileList && !deletedUser">
    <img class="deal-meta-avatar border-circle p-1" src="{{getAvatarImage()}}" appImgFallback='{{imageFallback}}'/>
    <a href="{{'profile_url'| translate}}/{{ deal.user_login }}">{{ deal.user_login }}</a>
  </div>
  <div class="deal-meta-shape h-1rem author" *ngIf="!profileList && deletedUser">
    <a class="ml-1 p-1" href="{{'profile_url'| translate}}/{{ deal.user_login }}">{{ 'deleted_user' | translate }}</a>
  </div>
  <div class="deal-meta-shape">
     <img class="deal-meta-time p-1" src="assets/clock.svg"/> {{deal.link_date | dateperiod}}
  </div>
  <div class="deal-meta-store-shape">
    <img class="deal-meta-time p-1" src="assets/store.svg"/><a [routerLink]="['/store',deal.link_store]">{{deal.link_store}}</a>
  </div>
</div>
<div class="flex flex-column col-6 pr-0 text-right">
  <p *ngIf="deal.link_price == 0" class="font-semibold text-3xl deal-preview-price justify-content-center mt-0 mb-0">{{'free'| translate}}</p>
  <p *ngIf="deal.link_price != 0" class="font-semibold text-3xl deal-preview-price justify-content-center mt-0 mb-0">{{ deal.link_price | number: '1.0-2'}}{{'currency_unit' | translate}}</p>
  <p *ngIf="oldPriceVisible" class="font-medium text-xl mt-0 mb-0 strikethrough">{{ deal.link_list_price | number: '1.0-2'}}{{'currency_unit' | translate}}</p>
  <p *ngIf="savingVisible" class="font-medium text-xl deal-preview-saving mt-0 mb-0">{{ deal.link_percent_off }}%</p>
</div>
