// import {window} from 'global';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import { isPlatformBrowser } from '@angular/common';


@Injectable()
export class JwtService {
  private readonly isBrowser: boolean;
  constructor(
      private cookieService: CookieService,
      @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }
  getToken(): string {
    if (this.cookieService.check('auth_token')) {
      return this.cookieService.get('auth_token');
    }
    return '';
  }

  saveToken(token: string) {
    if (this.isBrowser) {
      this.cookieService.set('auth_token', token, 7, '/');
    }
  }

  destroyToken() {
    if (this.isBrowser) {
      this.cookieService.delete('auth_token', '/');
    }
  }

}
