import {Inject, Injectable, Injector, NgZone, PLATFORM_ID} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';

import { JwtService } from '../services';
import {isPlatformServer} from '@angular/common';
import {tap} from 'rxjs/operators';
import {makeStateKey, StateKey, TransferState} from '@angular/platform-browser';
import * as memoryCache from 'memory-cache';
import {environment} from '../../../environments/environment';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private jwtService: JwtService,
              private transferState: TransferState,
              private ngZone: NgZone,
              @Inject(PLATFORM_ID) private platformId) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (isPlatformServer(this.platformId)) {
      const cachedData = memoryCache.get(req.url);
      if (cachedData) {
        this.transferState.set(makeStateKey(req.url), cachedData);
        return of(new HttpResponse({ body: cachedData, status: 200 }));
      }

      return next.handle(req).pipe(
          tap(event => {
            if (event instanceof HttpResponse) {
              this.transferState.set(makeStateKey(req.url), event.body);
              this.ngZone.runOutsideAngular(() => {
                memoryCache.put(req.url, event.body, 2 * 60);
              });
            }
          })
      );
    } else {
      const headersConfig = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      };

      const token = this.jwtService.getToken();

      if (token) {
        headersConfig['Authorization'] = `Token ${token}`;
      }

      const request = req.clone({ setHeaders: headersConfig });

      if (req.url.includes('_upload.php')) {
        if (token) {
          req = req.clone({
            headers: req.headers.set('Authorization', `Token ${token}`),
          });
        }
        return next.handle(req);
      } else {
        return next.handle(request);
      }
    }
  }
}
