// import {window} from 'global';
import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { DealListConfig, CategoriesService, UserService } from '../core';
import {Category} from '../core/models/category.model';
import {BehaviorSubject, Observable} from 'rxjs';
import Utils from '../utils';
import {TranslateService} from '@ngx-translate/core';
import {isPlatformBrowser} from '@angular/common';
import {environment} from '../../environments/environment';
import {Meta, Title} from '@angular/platform-browser';
import MiscUtil from '../core/utils/misc.util';

@Component({
  selector: 'app-home-page',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  constructor(
      private router: Router,
      private userService: UserService,
      private activatedRoute: ActivatedRoute,
      private cateogiriesService: CategoriesService,
      private translation: TranslateService,
      private metaService: Meta,
      private titleService: Title,
      @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }
  private readonly isBrowser: boolean;
  private sub: any;
  private categories: Array<Category> = [];
  isAuthenticated: boolean;
  listConfig: BehaviorSubject<DealListConfig> = new BehaviorSubject<DealListConfig>(null);
  breadcrumbTitle: string;
private performingQuery = false;
  ngOnInit() {
    this.breadcrumbTitle = this.translation.instant('home_index');
    this.cateogiriesService.currentCategories.subscribe(
        (categoriesData) =>
            this.categoriesReady(categoriesData)
    );
    this.userService.isAuthenticated.subscribe(
        (authenticated) => {
          this.isAuthenticated = authenticated;

          // set the deal list accordingly
          if (authenticated) {
            this.setListTo('feed');
          } else {
            this.setListTo('deals');
          }
        }
    );
  }
  categoriesReady(categories: Category[]) {
    if ( categories === null || Utils.countProps(categories) < 1) { return; }
    this.categories = categories;
    this.activatedRoute.queryParams.subscribe(params => {
      console.log('queryParams', params);
      if (!MiscUtil.isEmpty(params)) {
        this.performingQuery = true;
        this.setUpRoot(this.activatedRoute.snapshot.parent?.routeConfig?.path ?? '/', params);
      }
    });
    this.sub = this.activatedRoute.params.subscribe(params => {
      console.log('routeParams', params);
      if (!this.performingQuery) {
        this.setUpRoot(this.activatedRoute.snapshot.parent?.routeConfig?.path ?? '/', params);
      } else {
        this.performingQuery = false;
      }
    });
  }
  setUpRoot(path, params) {
    console.log(path, params);
    const seoDescription = this.translation.instant('seo_description_generic');
    this.titleService.setTitle(this.translation.instant('seo_title_generic'));
    switch (true) {
      case path.startsWith('pagina'):
      case path.startsWith('page'):
        const pageParams = {fn: 'deals', filters: {page: Number(params['page'])}};
        this.listConfig.next(pageParams);
        break;
      case path.startsWith('categoria'):
      case path.startsWith('category'):
        const selectedCategory = this.categories.filter(c => c.category_safe_name === params['catName']).pop();
        const categParams = {
          fn: 'deals', filters:
              {
                categories: selectedCategory.category_id.toString(),
                dealsType: 'category',
                page: Number(params['page'])
              }
        };
        this.listConfig.next(categParams);
        this.breadcrumbTitle = this.translation.instant('deals_in') + ' : ' + selectedCategory.category_name;
        this.titleService.setTitle(this.translation.instant('seo_title_category_profile_store') + '' + selectedCategory.category_name);
        break;
      case path.startsWith('cerca'):
      case path.startsWith('search'):
        const searchParams = {
          fn: 'deals', filters: {
            query: params['query'],
            dealsType: 'search',
            page: Number(params['page'])
          }
        };
        this.listConfig.next(searchParams);
        this.breadcrumbTitle = this.translation.instant('result_for') + ' : ' + decodeURI(searchParams.filters.query);
        this.titleService.setTitle(this.translation.instant('seo_title_search') + '' + decodeURI(searchParams.filters.query));
        break;
      case path.startsWith('negozio'):
      case path.startsWith('store'):
        const storeParams = {
          fn: 'deals', filters: {
            store: params['store'],
            page: Number(params['page'])
          }
        };
        this.listConfig.next(storeParams);
        this.breadcrumbTitle = this.translation.instant('deals_on') + ' ' + params['store'];
        this.titleService.setTitle(this.translation.instant('seo_title_category_profile_store') + '' + params['store']);
        break;
      case path.startsWith(this.translation.instant('filter_url')):
      case path.startsWith('filter'):
        const filterParams = {
          fn: 'deals', filters: {
            categories: params['categories'],
            minSaving: Number(params['minSaving']),
            minPrice: Number(params['minPrice']),
            maxPrice: Number(params['maxPrice']),
            onlyAvailable: params['onlyAvailable'],
            page: Number(params['page'])
          }
        };
        this.listConfig.next(filterParams);
        this.breadcrumbTitle = this.translation.instant('deals_for_selected_filters');
        this.titleService.setTitle(this.translation.instant('seo_title_filtered_deals'));
        break;
      default:
        const defParams = {fn: 'deals', filters: {}};
        this.listConfig.next(defParams);
        break;
    }
    this.setMetaTags(seoDescription);
    if (this.isBrowser) {
      window.scroll({
        top: 0,
        left: 0
      });
    }
  }
  setMetaTags(seoDescription: string) {
    this.metaService.addTags([
      {name: 'og:title', content: this.titleService.getTitle()},
      {name: 'og:type', content: 'deal'},
      {name: 'og:url', content: environment.server_url + this.router.url},
      {name: 'og:image', content: environment.assets_url + '/images/dlnd_square_icon.png'},
      {name: 'twitter:title', content: this.titleService.getTitle()},
      {name: 'twitter:url', content: environment.server_url + this.router.url},
      {name: 'twitter:image', content: environment.assets_url + '/images/dlnd_square_icon.png'},
      {name: 'description', content: seoDescription}
    ]);
  }
  setListTo(type: string = '', filters: Object = {}) {
    // If feed is requested but user is not authenticated, redirect to login
    if (type === 'feed' && !this.isAuthenticated) {
      this.router.navigateByUrl('/login');
      return;
    }

    // Otherwise, set the list object
    // this.listConfig = {fn: type, filters: filters};
  }

  onScrollDown($event: any) {
    // this.listConfig = {fn: 'deals', filters: {} };
  }
}
