export const environment = {
  language: 'en',
  locale: 'en-US',
  domain: 'dealandia.net',
  production: true,
  gtag_conversion: 'AW-10934408525/RGnMCMCUusYDEM2q990o',
  api_url: 'https://api.dealandia.net/libs/api/apiv2.php',
  assets_url: 'https://assets.dealandia.net',
  server_url: 'https://www.dealandia.net',
  upload_url: 'https://api.dealandia.net/libs/api/file_upload.php',
  twitter_url: 'https://twitter.com/DealandiaUS',
  telegram_url: 'https://t.me/BestOffersUS',
  facebook_url: 'https://facebook.com/DealandiaUS',
  kpw: 'pdJw2Fl13gFQQgtpoDSKgZfhcccfAPIu',
  ipv: 'ikaW6fWAFhuR6szf'
};
