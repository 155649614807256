import {Component, Directive, Injectable, Input, OnInit} from '@angular/core';
import {Deal, UserLevels} from '../../core';
import AssetsUtil from '../../core/utils/assets.util';

@Injectable()
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
abstract class DealMetaComponent implements OnInit {
  @Input() deal: Deal;
  @Input() avatar_url: String;
  @Input() profileList = false;
  oldPriceVisible: Boolean;
  savingVisible: Boolean;
  imageFallback = AssetsUtil.loadAvatarFallbackOnError();
  deletedUser: Boolean;
  ngOnInit() {
    this.deletedUser = (this.deal.user_level === UserLevels.Spammer || this.deal.user_level === UserLevels.Deleted);
    this.oldPriceVisible = (this.deal.link_list_price > this.deal.link_price && this.deal.link_list_price > 0);
    this.savingVisible = (this.deal.link_percent_off > 0);
  }
  getAvatarImage() {
   return AssetsUtil.get_small_avatar_url(this.deal.link_author);
  }
}

@Component({
  selector: 'app-deal-preview-meta',
  templateUrl: './deal-meta-preview.component.html',
})
export class DealPreviewMetaComponent extends DealMetaComponent {
}

@Component({
  selector: 'app-deal-meta',
  templateUrl: './deal-meta.component.html',
})export class DealDetailMetaComponent extends DealMetaComponent {}
