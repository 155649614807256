export interface User {
  userID: number;
  email: string;
  token: string;
  username: string;
  names: string;
  twitter: string;
  facebook: string;
  instagram: string;
  level: UserLevels;
}
export enum UserLevels {
  Spammer = 'spammer',
  Normal = 'normal',
  Admin = 'admin',
  Moderator = 'moderator',
  Deleted = 'deleted',
}
