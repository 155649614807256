import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {HttpHeaders, HttpClient, HttpParams, HttpRequest} from '@angular/common/http';
import { Observable ,  throwError } from 'rxjs';

import { JwtService } from './jwt.service';
import { catchError } from 'rxjs/operators';
import {ServerResponse} from '../models/server-response.model';

@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private jwtService: JwtService
  ) {}

  private formatErrors(error: any) {
    return  throwError(error.error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    params.keys().forEach((key => params.get(key) === undefined ? params = params.delete(key) : ''));
    return this.http.get(`${environment.api_url}${path}`, { params })
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: Object = {}): Observable<any> {
    Object.keys(body).forEach(key => body[key] === undefined ? delete body[key] : '');
    return this.http.put(
      `${environment.api_url}${path}`,
      JSON.stringify(body)
    ).pipe(catchError(this.formatErrors));
  }

  post(path: string, body: Object = {}): Observable<any> {
    Object.keys(body).forEach(key => body[key] === undefined ? delete body[key] : '');
    return this.http.post(
      `${environment.api_url}${path}`,
      JSON.stringify(body)
  ).pipe(catchError(this.formatErrors));
  }

  delete(path, params: HttpParams = new HttpParams()): Observable<any> {
    params.keys().forEach((key => params.get(key) === undefined ? params = params.delete(key) : ''));
    return this.http.delete(
      `${environment.api_url}${path}`, { params }
    ).pipe(catchError(this.formatErrors));
  }

  upload(file: File, avatar = false): Observable<any> {
    const formData: FormData = new FormData();
    const fn = (avatar) ? '?fn=updateAvatar' : '';

    formData.append('file', file, file.name);

    return this.http.post(
        `${environment.upload_url}` + fn,
        formData
    ).pipe(catchError(this.formatErrors));
  }
}
