<p-toast></p-toast>
<div class="p-2 deal-preview-card" [ngClass]="{'deal-expired': isExpired}">
    <div class="expired-label" *ngIf="isExpired">
        <div class="flex justify-content-around deal-meta-button" >
            <a class="deal-flat-button p-2 icon-disliked" *ngIf="deal.link_votes < 0 && isExpired"></a>
            <a class="deal-flat-button p-2 icon-liked" *ngIf="deal.link_votes >= 0 && isExpired"></a>
            {{deal.link_votes}}
            <div class="mr-2" *ngIf="isExpired">&nbsp;{{'expired' | translate}}</div>
        </div>
    </div>
    <div class="relative align-self-center text-center">
        <img src="{{imageDeal | dealimageurl}}"
             class="deal-preview-image cursor-pointer"
             routerLink="/{{'deal_url' | translate}}/{{deal.link_id}}-{{deal.link_title_url | urlSafe}}"
            [appImgFallback]="dealImageFallback">
    </div>
    <div class="flex flex-column">
        <a routerLink="/{{'deal_url' | translate}}/{{deal.link_id}}-{{deal.link_title_url | urlSafe}}" class="w-full">
            <p class="font-bold text-xl deal-preview-link">{{ deal.link_title | replacespaces}}</p>
        </a>
        <app-deal-preview-meta class="flex justify-content-between"
                               [deal]="deal" [profileList]="profileList"></app-deal-preview-meta>
        <div class="flex" [ngClass]="{'justify-content-around': isExpired,'justify-content-between': !isExpired }">
            <div class="flex flex-column flex-none">
                <div class="justify-content-around deal-meta-button">
                    <a href="#" class="deal-flat-button" routerLink="/{{'deal_url' | translate}}/{{deal.link_id}}-{{deal.link_title_url | urlSafe}}"><img src="assets/comment.svg">{{deal.link_comments}}</a>
                </div>
                <small>{{'comments' | translate}}</small>
            </div>
            <div class="flex-column flex-grow-1 mr-1 ml-1" style="max-width: 150px" *ngIf="!isExpired">
                <div class="flex justify-content-around deal-meta-button">
                    <a class="deal-flat-button p-2 icon-disliked" (click)="voteRemovePress(-10)" *ngIf="deal.vote_value === -10"></a>
                    <a class="deal-flat-button p-2 icon-dislike" (click)="votePress(-10)" *ngIf="deal.vote_value !== -10"></a>
                    {{deal.link_votes}}
                    <a class="deal-flat-button p-2 icon-liked" (click)="voteRemovePress(10)"  *ngIf="deal.vote_value === 10"></a>
                    <a class="deal-flat-button p-2 icon-like" (click)="votePress(10)"  *ngIf="deal.vote_value !== 10"></a>
                </div>
                <small *ngIf="!profileList">{{'votes' | translate}}</small>
            </div>
            <div class="flex flex-column flex-none">
                <a class="deal-preview-go-to-deal"  [routerLink]=""  (click)="goToUrl()"><img src="assets/cart.svg"></a>
                <small>{{'go_to_deal_btn' | translate}}</small>
            </div>
        </div>
    </div>
</div>
