import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Errors, UserService } from '../core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {CustomValidators} from './custom-validator';
import AuthUtil from '../core/utils/auth.util';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth.component.html',
  providers: [DialogService]
})
export class AuthComponent implements OnInit {

  authType: String = '';
  title: String = '';
  errors: Errors = {errors: {}};
  isSubmitting = false;
  authForm: FormGroup;
  forgetForm: FormGroup;
  passwordRepead: string;
  isLogin: boolean;
  isForget = false;
  passwordNoMatch: boolean;
  pwdCtrl = CustomValidators.mustMatch('password', 'passwordConfirm'); // insert here
  serverErrorId = -99;
  captchaOk = false;
  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private userService: UserService,
      private fb: FormBuilder,
      public ref: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private translate: TranslateService
  ) {
    // use FormBuilder to create a form group
    this.authForm = this.fb.group({
      'username': ['', Validators.required],
      'password': ['', Validators.required],
      'email': ['', Validators.email]
    });
    this.forgetForm = this.fb.group({
      'email': ['', Validators.email]
    });
  }

  ngOnInit() {
    this.authType = this.config.data;
    // this.route.url.subscribe(data => {
    //   // Get the last piece of the URL (it's either 'login' or 'signup')
    //   this.authType = data[data.length - 1].path;
    //   // Set a title for the page accordingly
    //   this.title = (this.authType === 'login') ? 'Sign in' : 'Sign up';
    //   // add form control for user_login if this is the signup page
    //   if (this.authType === 'signup') {
    //     this.authForm.addControl('user_login', new FormControl());
    //   }
    // });
    // Get the last piece of the URL (it's either 'login' or 'signup')
    // this.authType = data[data.length - 1].path;
    // Set a title for the page accordingly
    this.config.header = (this.authType === 'login') ? this.translate.instant('login_header') : this.translate.instant('signup');
    this.isLogin = (this.authType === 'login');
    // add form control for user_login if this is the signup page
    if (this.authType === 'signup') {
      this.authForm.addControl('passwordConfirm', new FormControl('', Validators.required));
      this.authForm.setValidators(this.pwdCtrl);
    }
    this.passwordNoMatch = (this.authForm.value.password !== this.passwordRepead);
  }
  get f() {
    return this.authForm.controls;
  }
  get forget() {
    return this.authForm.controls;
  }
  submitForm() {
    this.isSubmitting = true;
    // stop here if form is invalid
    if (this.authForm.invalid) {
      return;
    }

    this.errors = {errors: {}};
    const pwd = this.authForm.value.password;
    const user = this.authForm.value.username;
    const email = this.authForm.value.email;

    const credentials = { 'username' : user, 'email': email, 'password' : AuthUtil.generateEncryptedPwd(pwd), 'platform': 'WEB'};
    this.userService
        .attemptAuth(this.authType, credentials)
        .subscribe(
            data => this.ref.close(true),
            err => {
              this.errors = err;
              this.serverErrorId = err['error'];
              this.isSubmitting = false;
            }
        );
  }


  changeType(isForget: boolean = false, isLogin: boolean = this.isLogin) {
    this.captchaOk = false;
    this.isForget = isForget;
    this.isLogin = isLogin;
    if (isForget) {
      this.config.header =  this.translate.instant('reset_password');
    } else {
      if (!isLogin) {
        this.authType = 'signup';
        this.authForm.addControl('passwordConfirm', new FormControl('', Validators.required));
        this.authForm.setValidators(this.pwdCtrl);
      } else {
        this.authType = 'login';
        this.authForm.removeControl('passwordConfirm');
        this.authForm.removeValidators(this.pwdCtrl);
      }
      this.config.header = (this.authType === 'login') ? this.translate.instant('login_header') : this.translate.instant('signup');
    }
  }

  submitForgotPassword() {
    this.isSubmitting = true;
    // stop here if form is invalid
    if (this.forgetForm.invalid) {
      return;
    }

    const email = this.forgetForm.value.email;

    this.userService
        .requestReset(email)
        .subscribe(
            data => {
              this.serverErrorId = -1;
            },
            err => {
              this.errors = err;
              this.serverErrorId = err['error'];
              this.isSubmitting = false;
            }
        );
  }

    showResponse($event: any) {
      this.captchaOk = true;
    }
}
