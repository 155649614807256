import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {StatisticsModel} from '../models/statistics.model';

@Injectable()
export class StatisticsService {
    constructor (
        private apiService: ApiService
    ) {}

    getAll(): Observable<StatisticsModel> {
        return this.apiService.get('?fn=dlndStatistics')
            .pipe(map(data => data.data));
    }

}
