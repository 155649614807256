import {Component, OnInit} from '@angular/core';
import {CategoriesService} from '../../core';
import {Router} from '@angular/router';
import {StatisticsService} from '../../core/services/statistics.service';
import {StatisticsModel} from '../../core/models/statistics.model';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-layout-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  constructor(
      private statisticsService: StatisticsService
  ) {
  }
  today: number = Date.now();
  stats: StatisticsModel;
  ngOnInit(): void {
    this.statisticsService.getAll().subscribe( data => this.stats = data);
  }

  gotoSocial(social: string) {
    switch (social) {
      case 'telegram':
        window.open(environment.telegram_url, '_blank');
        break;
      case 'twitter':
        window.open(environment.twitter_url, '_blank');
        break;
      case 'facebook':
        window.open(environment.facebook_url, '_blank');
        break;
    }
  }
}
