import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule, TransferState } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { HomeModule } from './home/home.module';
import {
  FooterComponent,
  HeaderComponent,
  SharedModule
} from './shared';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import localeIT from '@angular/common/locales/it';
import localeUS from '@angular/common/locales/en';

import {registerLocaleData} from '@angular/common';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {AuthComponent} from './auth/auth.component';
import {InputTextModule} from 'primeng/inputtext';
import {PasswordModule} from 'primeng/password';
import {ButtonModule} from 'primeng/button';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CaptchaModule} from 'primeng/captcha';
import {NavigationService} from './core/services/navigation.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { translateBrowserLoaderFactory } from './shared/loaders/translate-browser.loader';
import {PrebootModule} from 'preboot';
import {environment} from '../environments/environment';

if (environment.language === 'it') {
  registerLocaleData(localeIT);
} else {
  registerLocaleData(localeUS);
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
const cookieConfig: NgcCookieConsentConfig = {
  'cookie': {
    'domain': environment.domain
  },
  'position': 'bottom',
  'theme': 'classic',
  'palette': {
    'popup': {
      'background': '#333333',
      'text': '#ffffff',
      'link': '#ffffff'
    },
    'button': {
      'background': '#fd5e04',
      'text': '#ffffff',
      'border': 'transparent'
    }
  },
  'type': 'info',
  'content': {
    'href': '/policy'
  }
};
@NgModule({
  declarations: [AppComponent, FooterComponent, HeaderComponent, AuthComponent],
  imports: [
    BrowserModule.withServerTransition({appId: 'web-dealandia'}),
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    HomeModule,
    AuthModule,
    AppRoutingModule,
    DynamicDialogModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
    TransferHttpCacheModule,
    NgcCookieConsentModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
      TransferHttpCacheModule,
      HttpClientModule,
      TranslateModule.forRoot({
          loader: {
              provide: TranslateLoader,
              useFactory: translateBrowserLoaderFactory,
              deps: [HttpClient, TransferState]
          }
      }),
    CaptchaModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: environment.locale},
      NavigationService
  ],
  exports: [
  ],
  bootstrap: [AppComponent],
  entryComponents: [AuthComponent]
})
export class AppModule {}
