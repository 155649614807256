<footer>
  <div class="flex flex-wrap justify-content-between footer_container header-style text-white">
    <div class="about info p-5">
      <div class="flex flex-grow-1 flex-shrink-1 footer-logo w-full bg-contain bg-no-repeat bg-left cursor-pointer" routerLink="/"></div>
      <p class="footer_description" [innerHTML]="'footer_text' | translate"></p>
      <br>
      <div class="button" style="display: flex;">
        <a class="sprite sprite-facebook" href="https://www.facebook.com/Dealandia" target="_blank"
           rel="nofollow"></a>
        <a class="sprite sprite-twitter" href="https://twitter.com/Dealandia_Italy" target="_blank"
           rel="nofollow"></a>
        <a class="sprite-telegram" href="https://t.me/dealandia" target="_blank" rel="nofollow"></a>
        <a class="sprite sprite-instagram" href="https://www.instagram.com/dealandia_it/" target="_blank"
           rel="nofollow"></a>
      </div>
    </div>
<!--    <div class="vl"></div>-->
    <div class="info p-5">
      <p class="text-xl font-semibold">{{'footer_numbers' | translate}}</p>
      <div class="footer_stat_wrapper">
        <p class="flex flex-row align-content-between align-items-center">
          <span class="pi pi-tag mr-2" style="transform: rotate(72deg);"></span>
          <span class="footer_stat_value" [innerText]="stats?.deals"></span>
          <span class="flex flex-grow-1 w-1rem"></span>
          <span class="footer_stat_text">{{'footer_deals' | translate}}</span>
        </p>
        <p class="flex flex-row align-content-between align-items-center">
          <span class="pi pi-comment mr-2"></span>
          <span class="footer_stat_value"[innerText]="stats?.comments"></span>
          <span class="flex flex-grow-1 w-1rem"></span>
          <span class="footer_stat_text">{{'comments' | translate}}</span>
        </p>
        <p class="flex flex-row align-content-between align-items-center">
          <span class="pi pi-heart mr-2"></span>
          <span class="footer_stat_value"[innerText]="stats?.votes"></span>
          <span class="flex flex-grow-1 w-1rem"></span>
          <span class="footer_stat_text">{{'votes' | translate}}</span>
        </p>
        <p class="flex flex-row align-content-between align-items-center">
          <span class="pi pi-users mr-2"></span>
          <span class="footer_stat_value"[innerText]="stats?.users"></span>
          <span class="flex flex-grow-1 w-1rem"></span>
          <span class="footer_stat_text">{{'footer_users' | translate}}</span>
        </p>
      </div>
    </div>

<!--    <div class="vl"></div>-->
    <div class="info p-5">
      <p class="text-xl font-semibold">{{'footer_information' | translate}}</p>
<!--      <p><a class="text-white font-medium" href="https://www.dealandia.it/regole">Regole per la pubblicazione</a></p>-->
      <p><a class="text-white font-medium" routerLink="/{{'terms_conditions_url' | translate}}">{{'terms_conditions' | translate}}</a></p>
      <p><a class="text-white font-medium" [routerLink]="['/policy']">{{'footer_privacy' |  translate}}</a></p>
      <p><a class="text-white font-medium" href="mailto:info@dealandia.it?subject=Mail dal sito">{{'contact_us' | translate}}</a></p>
    </div>
<!--    <div class="vl"></div>-->
    <div class="info p-5">
      <p class="text-xl font-semibold">{{'footer_app' | translate}}</p>
      <p><a href="{{'app_store_link_ios' | translate}}"><img src="assets/{{'download' | translate}}-app-store.svg"></a></p>
      <p><a href="{{'app_store_link_android' | translate}}"><img src="assets/{{'download' | translate}}-play-store.svg"></a></p>
      <div class="flex flex-row text-sm font-medium">
        <button pButton class="p-button-rounded p-button-header-social ml-2" icon="icon-telegram" (click)="gotoSocial('telegram')"></button>
        <button pButton class="p-button-rounded p-button-header-social ml-2" icon="icon-twitter" (click)="gotoSocial('twitter')"></button>
        <button pButton class="p-button-rounded p-button-header-social ml-2" icon="icon-facebook" (click)="gotoSocial('facebook')"></button>
      <p class="ml-2">{{'footer_social' | translate}}</p>
      </div>
    </div>
  </div>
  <div class="cpr text-center p-2 text-xs">{{'footer_affiliate' | translate}}</div>
  <div class="cpr text-center p-2 text-xs">Dealandia &copy; 2016 - {{ today | date: 'yyyy' }}  - {{'footer_copyright' | translate}}</div>
</footer>

