import {CategoryComponent} from './category.component';
import {NgModule} from '@angular/core';
import {SplitButtonModule} from 'primeng/splitbutton';
import {InputTextModule} from 'primeng/inputtext';
import {TreeSelectModule} from 'primeng/treeselect';
import {SharedModule} from '../shared.module';
import {BadgeModule} from 'primeng/badge';
import {DialogModule} from 'primeng/dialog';
import {MultiSelectModule} from 'primeng/multiselect';
import {DropdownModule} from 'primeng/dropdown';
import {SliderModule} from 'primeng/slider';
import {CheckboxModule} from 'primeng/checkbox';
import {ToastModule} from 'primeng/toast';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ConfirmDialogModule} from 'primeng/confirmdialog';

@NgModule({
    declarations: [
        CategoryComponent
    ],
    imports: [
        SplitButtonModule,
        InputTextModule,
        TreeSelectModule,
        SharedModule,
        BadgeModule,
        DialogModule,
        MultiSelectModule,
        DropdownModule,
        SliderModule,
        CheckboxModule,
        ToastModule,
        InputSwitchModule,
        ConfirmDialogModule
    ],
    exports: [
        CategoryComponent
    ]
})
export class CategoryModule {}
