import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {PrefilledDealModel} from '../models/prefilled-deal.model';
import { map } from 'rxjs/operators';


@Injectable()
export class PrepareDealService {
    constructor (
        private apiService: ApiService
    ) {}

    getPreparedDeal($url): Observable<PrefilledDealModel> {
        return this.apiService.get('?fn=prepareNewDeal&url=' + $url)
            .pipe(map(data => data?.data));
    }
}
