import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Filter} from '../models/filter.model';
import {ServerResponse} from '../models/server-response.model';


@Injectable()
export class FilterService {
    constructor (
        private apiService: ApiService
    ) {}

    add(categories, minPrice, maxPrice, minSaving, minLikes, availableOnly, query, authorID): Observable<Filter> {
        const body = {categories: categories.toString(),
            minPrice: minPrice,
            maxPrice: maxPrice,
            minSaving: minSaving,
            minLikes : minLikes,
            availableOnly:  availableOnly,
            query: query,
            authorID: authorID};
        return this.apiService
            .post(
                `?fn=saveFilter`,
                body
            ).pipe(map(data => data.data));
    }

    getAll(): Observable<Filter[]> {
        return this.apiService.get(`?fn=getFilters&nocache=1`)
            .pipe(map(data => data.data));
    }

    destroy(filterID): Observable<ServerResponse> {
        return this.apiService
            .delete(`?fn=deleteFilter&filterID=${filterID}`)
            .pipe(map(data => data));
    }

    edit(filterID, categories, minPrice, maxPrice, minSaving, minLikes, availableOnly, query, authorID): Observable<Filter>  {
        const body = {filterID: filterID,
            categories: categories.toString(),
            minPrice: minPrice,
            maxPrice: maxPrice,
            minSaving: minSaving,
            minLikes : minLikes,
            availableOnly:  availableOnly,
            query: query,
            authorID: authorID};
        return this.apiService
            .put(
                `?fn=updateFilter`,
                body
            ).pipe(map(data => data.data));
    }
}
