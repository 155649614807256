import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';
import { FlexLayoutModule} from '@angular/flex-layout';
import { BidiModule} from '@angular/cdk/bidi';
import {
  ApiService,
  DealsService,
  AuthGuard,
  CommentsService,
  JwtService,
  ProfilesService,
  TagsService,
  CategoriesService,
  UserService
} from './services';
import {PrepareDealService} from './services/prepare-deal.service';
import {DialogService} from 'primeng/dynamicdialog';
import {MessageService} from 'primeng/api';
import {StatisticsService} from './services/statistics.service';
import {NotificationService} from './services/notification.service';
import {FilterService} from './services/filter.service';

@NgModule({
  imports: [
    CommonModule,
    BidiModule,
    FlexLayoutModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    ApiService,
    DealsService,
    PrepareDealService,
    AuthGuard,
    CommentsService,
    JwtService,
    ProfilesService,
    TagsService,
    CategoriesService,
    StatisticsService,
    UserService,
    DialogService,
    MessageService,
    NotificationService,
    FilterService
  ],
  declarations: []
})
export class CoreModule { }
