import {environment} from '../../../environments/environment';
import {Params} from '@angular/router';

export default class MiscUtil {
    static chunkArrayInGroups(arr, size) {
        const myArray = [];
        for (let i = 0; i < arr.length; i += size) {
            myArray.push(arr.slice(i, i + size));
        }
        return myArray;
    }
    static fixedEncodeURIComponent(str) {
        return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
            return '%' + c.charCodeAt(0).toString(16);
        });
    }
    static copyToClipboard(str) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = str;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }
    static cleanArrayFromNilAndNan(arr) {
        const isNilOrNaN = (val) => val == null || Number.isNaN(val);
        return Object.fromEntries(Object.entries(arr).filter(([key, value]) => !isNilOrNaN(value)));
    }
    static isEmpty(obj) {
        return Object.keys(obj).length === 0 || obj === {};
    }
}
