<div class="surface-card w-full">
    <p-header>
        <div class="text-center mb-5">
            <span *ngIf="isLogin && !isForget" class="text-600 font-medium line-height-3">{{'login_no_account' | translate}}</span>
            <span *ngIf="!isLogin && !isForget" class="text-600 font-medium line-height-3">{{'signup_already_account' | translate}} </span>
            <a (click)="changeType(false, true)" *ngIf="isForget" class="font-medium no-underline ml-2 text-blue-500 cursor-pointer">{{'reset_password_back_login' | translate}}</a>
            <a (click)="changeType(false, true)" *ngIf="!isLogin && !isForget" class="font-medium no-underline ml-2 text-blue-500 cursor-pointer">{{'login' | translate}}</a>
            <a (click)="changeType(false, false)" *ngIf="isLogin && !isForget" class="font-medium no-underline ml-2 text-blue-500 cursor-pointer">{{'register_today' | translate}}</a>
        </div>
    </p-header>
    <form [formGroup]="authForm" *ngIf="!isLogin && !isForget" (ngSubmit)="submitForm()">
        <div class="formgrid grid app-auth-page-grid">
            <div class="field col">
                <label for="username" class="block text-900 font-medium ">{{'register_username' | translate}}</label>
                <input formControlName="username"
                       id="username"
                       type="username"
                       class="w-full "
                       autocomplete="username"
                       [ngClass]="{'ng-invalid ng-dirty' : (isSubmitting && f?.username?.errors != null) || (serverErrorId === 4)}"
                       pInputText />
                <small  *ngIf="isSubmitting && f?.username?.errors?.required" class="p-error block">{{'email_required' | translate}}</small>
                <small  *ngIf="(serverErrorId === 4)" class="p-error block">{{'register_username_unavailable' | translate}}</small>
            </div>
            <div class="field col">
                <label for="email" class="block text-900 font-medium ">{{'email' | translate}}</label>
                <input formControlName="email"
                       id="email"
                       type="email"
                       class="w-full "
                       autocomplete="email"
                       [ngClass]="{'ng-invalid ng-dirty' : (isSubmitting && f?.email?.errors != null) || (serverErrorId === 3)}"
                       pInputText />
                <small  *ngIf="isSubmitting && f.email.errors?.required" class="p-error block">{{'email_required' | translate}}</small>
                <small  *ngIf="(serverErrorId === 3)" class="p-error block">{{'email_already_registred' | translate}}</small>
            </div>
            <div class="field col">
                <label for="password" class="block text-900 font-medium ">{{'password' | translate}}</label>
                <p-password formControlName="password"
                            id="password"
                            class="p-password-input w-full"
                            inputStyleClass="w-full "
                            [ngClass]="{'ng-invalid ng-dirty' : (isSubmitting && f?.password?.errors != null) || (serverErrorId === 2)}"
                            [feedback]="false"></p-password>
                <small class="p-error block" *ngIf="isSubmitting && f?.password?.errors?.required != undefined">{{'password_required' | translate}}</small>
                <small class="p-error block" *ngIf="(serverErrorId === 2)">{{'password_not_valid' | translate}}</small>
            </div>
            <div class="field col">
                <label for="passwordConfirm" class="block text-900 font-medium ">{{'password_verify' | translate}}</label>
                <p-password formControlName="passwordConfirm"
                            id="passwordConfirm"
                            class="w-full"
                            inputStyleClass="w-full "
                            [ngClass]="{'ng-invalid ng-dirty' : isSubmitting && f?.passwordConfirm?.errors != null}"
                            [feedback]="false"></p-password>
                <small class="p-error block" *ngIf="isSubmitting && f?.passwordConfirm?.errors?.required != undefined">{{'password_required' | translate}}</small>
                <small class="p-error block" *ngIf="isSubmitting && f?.passwordConfirm?.errors?.mustMatch != undefined">{{'passwords_not_match' | translate}}</small>
            </div>
        </div>
        <p-captcha siteKey="6LeEE50fAAAAAJ1ijOqd1G33vgBg4u5L0ZhCoFDm" (onResponse)="showResponse($event)" initCallback="loadCaptcha"></p-captcha>
        <button pButton label="{{'signup'| translate}}"
                type="submit"
                [disabled]="!captchaOk"
                class="mt-2 mb-1 p-button-raised w-full"></button>
        <small innerHTML="{{'register_policy'| translate}}"></small>
    </form>
    <form [formGroup]="authForm" *ngIf="isLogin && !isForget" (ngSubmit)="submitForm()">
        <div class="formgrid grid app-auth-page-grid">
            <div class="field col-12">
                <label for="username" class="block text-900 font-medium ">{{'user' | translate}}</label>
                <input formControlName="username"
                       id="username"
                       type="username"
                       class="w-full "
                       autocomplete="username"
                       [ngClass]="{'ng-invalid ng-dirty' : (isSubmitting && f?.username?.errors != null) || (serverErrorId === 1)}"
                       pInputText />
                <small  *ngIf="isSubmitting && f?.username?.errors?.required != undefined" class="p-error block">{{'user_required' | translate}}</small>
                <small  *ngIf="(serverErrorId === 1)" class="p-error block">{{'user_not_exist' | translate}}</small>
            </div>
            <div class="field col-12">
                <label for="password" class="block text-900 font-medium ">{{'password' | translate}}</label>
                <p-password formControlName="password"
                            id="password"
                            class="w-full"
                            inputStyleClass="w-full "
                            [ngClass]="{'ng-invalid ng-dirty' : (isSubmitting && f?.password?.errors != null) || (serverErrorId === 2)}"
                            [feedback]="false">
                </p-password>
                <small class="p-error block" *ngIf="isSubmitting && f?.password?.errors?.required != undefined">{{'password_required' | translate}}</small>
                <small class="p-error block" *ngIf="(serverErrorId === 2)">{{'password_not_valid' | translate}}</small>
            </div>
        </div>
        <button pButton label="Login"
                type="submit"
                class="p-button-raised w-full mb-3"></button>
        <a (click)="changeType(true)" *ngIf="isLogin" class="font-medium no-underline text-blue-500 cursor-pointer">{{'password_forgot' | translate}}</a>
    </form>
    <form [formGroup]="forgetForm" *ngIf="isForget" (ngSubmit)="submitForgotPassword()">
        <div class="formgrid grid app-auth-page-grid">
            <div class="field col-12">
                <label for="username" class="block text-900 font-medium ">{{'email' | translate}}</label>
                <input formControlName="email"
                       id="email"
                       type="email"
                       class="w-full "
                       autocomplete="email"
                       [ngClass]="{'ng-invalid ng-dirty' : (isSubmitting && f?.email?.errors != null) || (serverErrorId === 31)}"
                       pInputText />
                <small  *ngIf="isSubmitting && forget?.email?.errors?.required != undefined" class="p-error block">{{'email_required' | translate}}</small>
                <small  *ngIf="(serverErrorId === 31)" class="p-error block">{{'email_not_exist' | translate}}</small>
                <small  *ngIf="(serverErrorId === -1 && isSubmitting)" class="text-green-500 block">{{'email_send_ok' | translate}}</small>
            </div>
        </div>
        <p-captcha siteKey="6LeEE50fAAAAAJ1ijOqd1G33vgBg4u5L0ZhCoFDm" (onResponse)="showResponse($event)" initCallback="loadCaptcha"></p-captcha>
        <button pButton label="{{'reset_password' | translate}}"
                type="submit"
                [disabled]="!captchaOk"
                class="mt-2 p-button-raised w-full"></button>
    </form>
</div>
